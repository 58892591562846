import "./single.css"
import React, { useRef, useState } from "react"
import { Link, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import reactStringReplace from "react-string-replace"
import CountUp from "react-countup"

// We're using Gutenberg so we need the block styles
// these are copied into this project due to a conflict in the postCSS
// version used by the Gatsby and @wordpress packages that causes build
// failures.
// @todo update this once @wordpress upgrades their postcss version
import "@/css/@wordpress/block-library/build-style/style.css"
import "@/css/@wordpress/block-library/build-style/theme.css"

import Layout from "@/templates/layout"
import StickyMenu, { MenuItem, setupScrollSpy } from "@/components/sticky-menu"
import { Page } from "@/components/page"
import { ProsCons } from "@/components/site-card"
import FAQs from "@/components/faqs"
import { useTranslation } from "gatsby-plugin-react-i18next"
import AuthorBlock from "@/components/author-block"

interface Props {
  data: {
    page: Page
  }
}

const PostTemplate = (props: any) => {
  const { i18n } = useTranslation()
  const { data: { page } } = props as Props
  const refContent = useRef<HTMLDivElement>(null)

  let hasSidebar = false
  let title = page.title
  let hasAlternateLinks = true
  if (page.categories.nodes.map(node => node.slug).join(" ").indexOf("no-alternate-links") > -1) {
    hasAlternateLinks = false
  }
  if (page.slug.indexOf("policy")) { hasAlternateLinks = false }
  if (page.slug.indexOf("divulgation")) { hasAlternateLinks = false }
  if (page.slug.indexOf("disclosure")) { hasAlternateLinks = false }
  if (page.categories.nodes.map(node => node.slug).join(" ").indexOf("has-sidebar") > -1) {
    hasSidebar = true
  }
  if (page.slug.includes("statistics")) {
    hasSidebar = true
    title = "Statistics"
  }

  const featuredImage = {
    data: page.featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData,
    alt: page.featuredImage?.node?.alt || ``,
  }

  const prefixes: any = ["en", "x-default"]
  const translations = page.translations
  .filter(t => ["en", "fr"].includes(t.locale.split("_")[0]))
  .map(t => {
    const lang = t.locale.split("_")[0]
    const urlPrefix = lang === "en" ? "https://www.madcrypto.com" : "https://fr.madcrypto.com"
    const url = `${urlPrefix}${t.href.replace(`/${lang}`, "")}`
    return { lang, url }
  })
  for (const t of translations) {
    prefixes.push(t)
  }

  const seo = {
    title: page.seoTitle,
    description: page.seoDescription,
    schema: page.seoSchema,
    lang: i18n.language,
    url: page.slug,
    prefixes: prefixes,
  }

  if (!hasAlternateLinks) {
    seo.prefixes = []
  }

  const [items, setItems] = useState<MenuItem[]>([])
  setupScrollSpy(refContent, 'h2, .site-card .site-link', [page.content], setItems)

  const headerClassNames = ["site-header"]
  if (featuredImage?.data) {
    headerClassNames.push("site-header-has-image")
  }

  const articleClassNames = ["blog-post", "page-single", page.slug]
  let content: string | React.ReactNodeArray = page.content

  content = reactStringReplace(content, /\!\!\!FAQS ([a-zA-Z_0-9\-]+) \!\!\!/, (match: string, i: number) => {
    return <FAQs category={match} />
  })

  content = reactStringReplace(content, "!!!PROS_AND_CONS!!!", (match: string, i: number) => {
    return <ProsCons pros={page.extraFields.pros} cons={page.extraFields.cons} />
  })

  content = reactStringReplace(content, /\[countup\s+([0-9,]+)\s*\]/, (match: string) => {
    const value = parseInt(match.replace(/[^0-9]+/g, ''), 10)
    return <span className="count-up">
      <CountUp end={value} separator="," />
    </span>
  })

  const mainContent = <section className="main-content" itemProp="articleBody">
    {content.map((section) => {
      if (typeof section === "string") {
        return <div dangerouslySetInnerHTML={{__html: section }} />
      } else {
        return section
      }
    })}
  </section>


  return (
    <Layout {...props} seo={seo} title={title}>
      <article className={articleClassNames.join(" ")}>
        <header className={headerClassNames.join(" ")}>
          <div>
            <h1 itemProp="headline">{page.title}</h1>
            {page.author && <AuthorBlock author={page.author.node} modified={page.modified} /> }
          </div>
          {/* if we have a featured image for this post let's display it */}
          {featuredImage?.data && (<GatsbyImage image={featuredImage.data} alt={featuredImage.alt} />)}
        </header>

        <hr />

        {hasSidebar && 
          <div className="container container-columns">
            <div className="column-sidebar">
              <div className="sticky">
                <StickyMenu items={items} />
              </div>
            </div>
            <div className="column-primary container-menu-items" ref={refContent}>
              {mainContent}
            </div>
          </div>
        }

        {!hasSidebar && 
          <div className="container container-columns">
            <div className="column-single container-menu-items" ref={refContent}>
              {mainContent}
            </div>
          </div>
        }
        <hr />
      </article>
    </Layout>
  )
}

export default PostTemplate

export const pageQuery = graphql`
  query (
    $id: String!,
    $language: String!
  ) {
    page: wpPage(id: { eq: $id }) {
      ...PageInformation
    }
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
